import { action, cache } from "@solidjs/router";
import * as server from "./server";
import * as imply from "./implies";
import * as know from "./knows";
import * as review from "./reviews";
import * as article from "./articles";

export const getUserCache = cache(server.getUser, "getUserCache");
export const currentUserIdCache = cache(
  server.currentUserId,
  "currentUserIdCache"
);
export const isAuthedCache = cache(server.isAuthed, "isAuthed");
export const fetchUserCache = cache(server.fetchUser, "fetchUserCache");
export const logoutAct = action(server.logout, "logoutAct");
export { signUser } from "./server";
export const signUserAct = action(server.signUser, "signUserAct");

export const createKnowAct = action(know.create);
export const updateKnowAct = action(know.update, "updateKnowAct");
export const queryKnowImpliesCache = cache(know.query, "queryKnowImpliesCache");

export const getArticleCache = cache(article.fetch, "getArticleCache");

export const queryReviewCache = cache(review.query, "queryReviewCache");
export const createReviewAct = action(review.create, "createReviewAct");
export const editReviewAct = action(review.edit, "editReviewAct");

export const queryImplyFullCache = cache(imply.find, "queryImplyFullCache");
export const UpdateImplyAct = action(imply.update, "UpdateImplyAct");
export const createImplyAct = action(imply.create, "createImplyAct");
