"use server";
import { redirect } from "@solidjs/router";
import { createId } from "@paralleldrive/cuid2";
import { SDK } from "casdoor-nodejs-sdk";
import { type HTTPEvent, useSession } from "vinxi/http";
import { UserSession, sessionConf } from "./session";
import * as AccountRepo from "~/repos/account";
import { fetchUser } from "~/repos/account";
export { fetchUser };

const authCfg = {
  endpoint: process.env.VITE_CASDOOR_ENDPOINT || "https://door.casdoor.com",
  orgName: process.env.VITE_CASDOOR_ORGNAME || "org name",
  appName: process.env.VITE_CASDOOR_APPNAME,
  clientId: process.env.VITE_CASDOOR_APPID || "app",
  clientSecret: process.env.CASDOOR_APPSECRET || "secrect",
  certificate: process.env.CASDOOR_CERT || "cert",
};
const sdk = new SDK(authCfg);

export async function getSession(event?: HTTPEvent) {
  return await useSession<UserSession>(sessionConf);
}

export const isAuthed = async (event?: HTTPEvent) => {
  return Boolean(await currentUserId(event));
};

export const currentUserId = async (event?: HTTPEvent) => {
  const session = await getSession(event);
  return session.data?.userId;
};

export const getUser = async () => {
  const session = await getSession();
  const userId = session.data.userId;
  if (userId === undefined) return undefined;

  try {
    const user = await AccountRepo.find(userId);
    return user;
  } catch {
    // logout();
    return undefined;
  }
};

interface WithTimestamp {
  createdAt: Date;
  updatedAt?: Date | null;
}

type WithNumericTimestamps<T extends WithTimestamp> = Omit<
  T,
  "createdAt" | "updatedAt"
> & {
  createdAt: number;
  updatedAt?: number;
};

export function timestampRecord<T extends WithTimestamp>(
  rec: T
): WithNumericTimestamps<T> {
  return {
    ...rec,
    createdAt: +rec.createdAt / 1000,
    updatedAt: rec.updatedAt ? +rec.updatedAt / 1000 : undefined,
  } as WithNumericTimestamps<T>;
}

export const logout = async () => {
  const session = await getSession();
  await session.update((d) => (d.userId = undefined));
  throw redirect("/");
};

export const signUser = async (code: string) => {
  const token = await sdk.getAuthToken(code);
  const user = sdk.parseJwtToken(token.access_token);

  const account = await AccountRepo.create({
    id: createId(),
    ssoId: user.id,
    avatar: user.avatar,
    name: user.name,
    permanentAvatar: user.permanentAvatar,
    language: user.language,
    displayName: user.displayName,
    isAdmin: user.isAdmin,
  });

  const session = await getSession();
  // return new Response("hellow");
  await session.update({ userId: account.id });

  return token;
};
