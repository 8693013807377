"use server";

import { createId } from "@paralleldrive/cuid2";
import { currentUserId, timestampRecord } from "./server";
import { authorSim } from "~/repos/account";
import * as reviewRepo from "~/repos/review";
import * as implyRepo from "~/repos/imply";
import * as knowRepo from "~/repos/know";

export const create = async (formData: FormData) => {
  const authorId = await currentUserId();
  if (!authorId) throw "not logged in";
  const author = await authorSim(authorId);
  if (!author) throw "author not found";

  const implyId = formData.get("implyId")?.toString() || "";
  const imply = await implyRepo.find(implyId);
  if (!imply) throw "imply not found";

  const content = formData.get("content")?.toString() || "";
  await reviewRepo.create({
    id: createId(),
    implyId,
    content,
    authorId,
  });
  // ["id", "content", "implyId", "authorId", "createdAt", "updatedAt"]
};

export const query = async (id: string) => {
  const data = await reviewRepo.find(id);
  if (!data) throw "not found review";
  const imply = await implyRepo.find(data.implyId);
  let review;
  if (imply) {
    const know = await knowRepo.find(imply.knowId);
    const propositions = await knowRepo.getList(imply.propositionIds);
    review = {
      ...data,
      imply: { ...imply, know, propositions },
    };
  } else {
    review = {
      ...data,
      imply,
    };
  }
  return review;
};

export const edit = async (formData: FormData) => {
  const content = formData.get("content")?.toString();
  const id = formData.get("id")?.toString();
  if (!id) throw "invalid id";
  if (!content) throw "invalid content";
  await reviewRepo.update(id, { content });
};
