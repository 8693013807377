"use server";

import type { Imply, ImplyUpdate, Know } from "~/types/models";
import { timestampRecord } from "./server";
import { meiliServerImplies, getRedis } from "~/lib/server";
import { authorSim } from "~/repos/account";
import * as repo from "~/repos/imply";
import * as knowRepo from "~/repos/know";
import { currentUserId } from "./server";
import { DATA_STORE, HOME_LIST_ID } from "~/lib/const";

// const reviews: (Review & { author: Account })[] = await knex<Review>(
//   "reviews"
// )
//   .where("implyId", imply.id)
//   .leftJoin<Account>(
//     "accounts as author",
//     "reviews.authorId",
//     "=",
//     "author.id"
//   )
//   // https://github.com/knex/knex/issues/882#issuecomment-1351747332
//   .select([
//     "reviews.*",
//     knex.raw("(json_agg(author.*) ->> 0)::json as author"),
//   ])
//   .orderBy("reviews.createdAt", "asc")
//   .groupBy(["reviews.id", "reviews.authorId", "author.id"]);

export const query = repo.query;

export const find = async (
  id: string
): Promise<(Imply & { propositions: Know[]; know?: Know }) | undefined> => {
  const imply = await repo.find(id);
  if (!imply) return undefined;
  const know = await knowRepo.find(imply.knowId);
  const propositions = await knowRepo.getList(imply.propositionIds);

  return { ...imply, propositions, know };
};

export const create = async (
  knowId: string,
  val: {
    id: string;
    propositionIds: string[];
    inference: string;
    negative: boolean;
  }
) => {
  const authorId = await currentUserId();
  if (!authorId) throw "user not authed";
  const input = { knowId, authorId, ...val };
  const data = await repo.create(input);
  const doc = await getFullImply(data);
  await meiliServerImplies.addDocuments([doc]);
};

export const update = async (id: string, val: ImplyUpdate) => {
  const data = await repo.update(id, val);
  const doc = await getFullImply(data);
  if (data.publishedAt) {
    await meiliServerImplies.updateDocuments([doc]);
    const rc = await getRedis();
    console.log("update redis cache");
    await rc.zAdd(HOME_LIST_ID, {
      score: -doc.createdAt,
      value: "imply:" + id,
    });
    await rc.hSet(DATA_STORE, "imply:" + id, JSON.stringify(doc));
  } else {
    await meiliServerImplies.deleteDocument(id);
    const rc = await getRedis();
    await rc.zRem(HOME_LIST_ID, "imply:" + id);
    await rc.hDel(DATA_STORE, "imply:" + id);
  }
};

async function getFullImply(data: Imply) {
  const know = await knowRepo.get(data.knowId);
  const author = (await authorSim(data.authorId)) || null;
  const propositions = await knowRepo.getList(data.propositionIds);
  const { publishedAt, ...val } = data;
  const imply = {
    ...timestampRecord(val),
    author,
    know,
    propositions,
    publishedAt: publishedAt ? +publishedAt / 1000 : undefined,
  };
  return imply;
}
